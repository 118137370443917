import styles from './CardAnswerTextRow.module.scss';
import { SwipeQuestionOptionSide } from '../../../../../models/SwipeQuestionOptionSide.enum';

interface Props {
  idx: number;
  isImageCard: boolean;
  cardSwipeDirection: null | SwipeQuestionOptionSide;
  rightText: string;
  leftText: string;
}

export const CardAnswerTextRow = ({
  idx,
  isImageCard,
  cardSwipeDirection,
  leftText,
  rightText,
}: Props) => {
  return (
    <div
      className={`${styles.cardAnswerTextRow} ${
        isImageCard ? styles.padding : ''
      }`}
    >
      <span
        className={
          idx === 0 && cardSwipeDirection === SwipeQuestionOptionSide.RIGHT
            ? styles.visible
            : styles.hidden
        }
      >
        {rightText}
      </span>
      <span
        className={
          idx === 0 && cardSwipeDirection === SwipeQuestionOptionSide.LEFT
            ? styles.visible
            : styles.hidden
        }
      >
        {leftText}
      </span>
    </div>
  );
};
