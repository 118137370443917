import Highcharts from 'highcharts';
import wordCloud from 'highcharts/modules/wordcloud';
import React, { useEffect, useState } from 'react';

type wordCloudData = {
  name: string;
  weight: number;
};

interface Props {
  data: wordCloudData[];
}
const MAX_WIDTH = 980;
const MAX_HEIGHT = 600;
const SCREEN_PADDING = 30;

const adjustWidthAndHeight = (innerWidth: number) => {
  if (innerWidth >= MAX_WIDTH)
    return { width: MAX_WIDTH - SCREEN_PADDING, height: MAX_HEIGHT };
  else {
    return { width: innerWidth - SCREEN_PADDING, height: innerWidth * 0.75 };
  }
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const WordCloud = ({ data }: Props) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  const [width, setWidth] = useState<number>(
    adjustWidthAndHeight(windowDimensions.width).width,
  );

  const [height, setHeight] = useState<number>(
    adjustWidthAndHeight(windowDimensions.width).height,
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setWidth(adjustWidthAndHeight(windowDimensions.width).width);
    setHeight(adjustWidthAndHeight(windowDimensions.width).height);
  }, [windowDimensions]);

  const ref = React.useRef<HTMLDivElement | null>(null);

  const color =
    document.documentElement.style.getPropertyValue('--button-background') ||
    '#1b87e6';

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }

    wordCloud(Highcharts);

    Highcharts.chart(ref.current, {
      series: [
        {
          type: 'wordcloud',
          data: data,
          colors: [color],
          name: 'Mentions',
          style: {
            fontFamily: "'Fira Sans', sans-serif",
          },
        },
      ],
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      chart: {
        width: width,
        height: height,
        backgroundColor: '#f5f5f5',
      },
    });
  }, [color, data, height, width]);

  return (
    <div
      style={{ width: `${width}px`, height: `${height}px` }}
      ref={ref}
      data-testid="wordCloudQuestionResultChart"
    />
  );
};
