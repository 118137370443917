import { QuestionDifficultyLevel } from '@livepolls/ui-components/src/enums/question-difficulty-level.enum';
import React, { useState } from 'react';
import { CompanyLogo } from './components/company-logo/CompanyLogo';
import { ExitLink } from './components/header/ExitLink';
import { Header } from './components/header/Header';
import { PoweredBy } from './components/powered-by/PoweredBy';
import { Spinner } from './components/spinner/Spinner';
import {
  useError,
  useGetQuestionForPermanentRoom,
  useSubmitAnswer,
  useSubmitComment,
  useSubmitMultiSelectAnswerIds,
  useSubmitSwipeQuestion,
  useSubmitWordCloudAnswer,
  useTakeLivePollState,
} from './contexts/takeLivePollStateContext';
import { IAnswer } from './models/Answer';
import { IChoiceQuestion } from './models/ChoiceQuestion';
import { IWordCloudQuestion } from './models/IWordCloudQuestion';
import { LivePollSessionState } from './models/LivePollSessionState';
import { LivePollSessionType } from './models/LivePollSessionType';
import { LivePollType } from './models/LivePollType';
import { IQuestion } from './models/Question';
import { QuestionType } from './models/QuestionType';
import styles from './PermanentRoomScreen.module.scss';
import { Answers } from './screens/question-started/components/Answers';
import { LiveFeedQuestionStarted } from './screens/question-started/components/LiveFeedQuestionStarted';
import { MultiSelectQuestionStarted } from './screens/question-started/components/MultiSelectQuestionStarted';
import { WordCloudQuestionStarted } from './screens/question-started/components/WordCloudQuestionStarted';
import { redirectTo } from './screens/utils/redirectUtil';
import { setTheme } from './screens/utils/themes/setThemes';
import { SwipeQuestionStarted } from './screens/question-started/components/swipe-question/SwipeQuestionStarted';
import { ISwipeQuestion } from './models/SwipeQuestion';
import { SwipeQuestionOptionSide } from './models/SwipeQuestionOptionSide.enum';

export const PermanentRoomScreen = () => {
  const [themeLoaded, setThemeLoaded] = useState<boolean>(false);
  const { loadingSession, serverState } = useTakeLivePollState();
  const { respondent, score } = serverState;
  const currentQuestion = serverState.question;
  const theme = serverState.livePollSession?.setting?.theme.setting!;
  const [submittingAnswer, setSubmittingAnswer] = useState<boolean>(false);

  const submitMultiSelectAnswerIds = useSubmitMultiSelectAnswerIds();
  const submitAnswer = useSubmitAnswer();
  const submitWordCloudAnswer = useSubmitWordCloudAnswer();
  const submitComment = useSubmitComment();
  const submitSwipeQuestion = useSubmitSwipeQuestion();
  const getQuestion = useGetQuestionForPermanentRoom();
  const error = useError();

  React.useEffect(() => {
    if (!themeLoaded) {
      setTheme(theme);
      setThemeLoaded(true);
    }
  }, [theme, themeLoaded]);

  if (loadingSession) {
    return <Spinner message="Loading session..." />;
  }
  const livePollSession = serverState.livePollSession;

  if (!livePollSession) {
    return (
      <div>
        Oops an error occurred loading app (Unable to load LivePollSession).
      </div>
    );
  }

  let content = undefined;
  const rightHeaderLink = <ExitLink />;
  const isQuiz = livePollSession.livePollType === LivePollType.QUIZ;
  const enable = serverState.permanentRoom?.enable;

  if (!enable) {
    alert('Room is closed');
  }

  if (livePollSession.state === LivePollSessionState.COMPLETED) {
    redirectTo(`${respondent.uid}/finish-session`);
  }

  if (!currentQuestion) {
    return <Spinner message="Loading Question..." />;
  }

  const displayDifficultyLevel =
    !!livePollSession.setting?.displayDifficultyLevels;

  const questionDifficultyLevel =
    livePollSession.setting?.enableQuestionDifficulty &&
    currentQuestion.difficultyLevel != null
      ? currentQuestion.difficultyLevel
      : QuestionDifficultyLevel.NORMAL;

  const handleSubmitMultiSelectAnswer = (
    answerIds: string[],
  ): (() => Promise<void>) => {
    return async () => {
      setSubmittingAnswer(true);
      await submitMultiSelectAnswerIds(
        LivePollSessionType.PERMANENT_ROOM_SESSION,
        currentQuestion.id,
        answerIds,
      );
      getQuestion();
      setSubmittingAnswer(false);
    };
  };

  const handleSubmitAnswer = (answer: IAnswer): (() => Promise<void>) => {
    return async () => {
      setSubmittingAnswer(true);
      await submitAnswer(
        LivePollSessionType.PERMANENT_ROOM_SESSION,
        currentQuestion.id,
        answer.id,
      );
      getQuestion();
      setSubmittingAnswer(false);
    };
  };

  const submitWordCloudAnswers = async (
    questionId: string,
    text: string,
    remainingResponses: number,
  ) => {
    await submitWordCloudAnswer(
      LivePollSessionType.PERMANENT_ROOM_SESSION,
      questionId,
      text,
    );
    if (remainingResponses === 0) {
      getQuestion();
    }
  };

  const submitLiveFeedComment = async (questionId: string, text: string) => {
    await submitComment(
      LivePollSessionType.PERMANENT_ROOM_SESSION,
      questionId,
      text,
    );
    getQuestion();
  };

  const handleSubmitSwipeQuestion = async (
    questionId: string,
    cardId: string,
    selectedSide: SwipeQuestionOptionSide,
    remaininGCards: number,
  ) => {
    await submitSwipeQuestion(
      LivePollSessionType.PERMANENT_ROOM_SESSION,
      questionId,
      cardId,
      selectedSide,
    );
    if (remaininGCards === 0) {
      await getQuestion();
    }
  };

  if (error) {
    content = (
      <div className={styles.errorMessage} data-testid="errorMsg">
        {error.message}
      </div>
    );
  }

  if (submittingAnswer) {
    return <Spinner message="Answer Submitting..." />;
  }

  if (enable) {
    if (currentQuestion.type === QuestionType.TEXT) {
      content = (
        <LiveFeedQuestionStarted
          question={currentQuestion as IQuestion}
          submitLiveFeedComment={submitLiveFeedComment}
        />
      );
    } else if (currentQuestion.type === QuestionType.WORD_CLOUD) {
      content = (
        <WordCloudQuestionStarted
          question={currentQuestion as IWordCloudQuestion}
          submitWordCloudAnswer={submitWordCloudAnswers}
        />
      );
    } else if (currentQuestion.type === QuestionType.CHOICE) {
      if (currentQuestion.isMultiSelect) {
        content = (
          <MultiSelectQuestionStarted
            question={currentQuestion as IChoiceQuestion}
            onAnswerSelected={handleSubmitMultiSelectAnswer}
            questionDifficultyLevel={
              displayDifficultyLevel ? questionDifficultyLevel : null
            }
          />
        );
      } else {
        content = (
          <Answers
            question={currentQuestion as IChoiceQuestion}
            onAnswerSelected={handleSubmitAnswer}
            questionDifficultyLevel={
              displayDifficultyLevel ? questionDifficultyLevel : null
            }
          />
        );
      }
    } else if (currentQuestion.type === QuestionType.SWIPE) {
      content = (
        <SwipeQuestionStarted
          key={currentQuestion.id}
          question={currentQuestion as ISwipeQuestion}
          submitSwipeAnswer={handleSubmitSwipeQuestion}
        />
      );
    }
  }

  if (!content) {
    return <Spinner message="Loading..." />;
  }

  return (
    <div className={styles.container}>
      <Header
        isQuiz={isQuiz}
        score={score}
        respondentName={respondent.respondentName}
        rightLink={rightHeaderLink}
        respondentTeamUid={respondent.teamUid}
      />
      <div className={styles.innerContainer}>
        <div className={styles.questionStartedInnerContainer}>{content}</div>
      </div>
      <div className={styles.companyLogoAndBrand}>
        <CompanyLogo />
        <PoweredBy />
      </div>
    </div>
  );
};
